import * as React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { Content, HTMLContent } from "../components/Content";

export class DesignPageTemplate extends React.Component<{
  content;
  contentComponent?;
  description;
  title;
}> {
  render() {
    const PostContent = this.props.contentComponent || Content;

    return (
      <Layout>
        <Helmet title={this.props.title}>
          <meta name="description" content={this.props.description} />
        </Helmet>
        <section>
          <div>
            <div>
              <div>
                <h1>{this.props.title}</h1>
                <p>{this.props.description}</p>
                <PostContent content={this.props.content} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

class DesignPage extends React.Component<{ data }> {
  render() {
    const { markdownRemark: post } = this.props.data;

    return (
      <DesignPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        title={post.frontmatter.title}
      />
    );
  }
}

export default DesignPage;

export const pageQuery = graphql`
  query DesignPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
